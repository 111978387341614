<template>
  <div class="page">
    <el-carousel>
      <div :class="{'ratio': devicePixelRatio === 2}">
        <el-carousel-item v-for="item in 3" :key="item"></el-carousel-item>
      </div>
    </el-carousel>
    <div class="block loan">
      <div class="title">{{ $t('homePage.loan.title') }}</div>
      <div class="content">
        <div class="item" v-for="(item, index) in $t('homePage.loan.list')" :key="index">
          <div class="icon" />
          <div class="text">{{ item.text }}</div>
          <div class="info" v-html="item.info"></div>
        </div>
      </div>
    </div>
    <div class="block step">
      <div class="title">{{ $t('homePage.step.title') }}</div>
      <div class="content">
        <div class="item" v-for="(item, index) in $t('homePage.step.list')" :key="index">
          <div class="icon" />
          <div class="text">
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      devicePixelRatio: window.devicePixelRatio,
    };
  },
  components: {

  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {
  @media screen and (-webkit-min-device-pixel-ratio: 1),
  screen and (min--moz-device-pixel-ratio: 1) {
    @import '../assets/scss/sprite/home.scss';
    $list: $icon-1, $icon-2, $icon-3;
    .loan .content {
      @for $i from 1 to length($list) + 1 {
        .item:nth-child(#{$i}) .icon {
          @include sprite(nth($list, $i));
        }
      }
    }
    $list-2: $step-1, $step-2, $step-3, $step-4;
    .step .content {
      @for $i from 1 to length($list-2) + 1 {
        .item:nth-child(#{$i}) .icon {
          @include sprite(nth($list-2, $i));
        }
      }
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2) {
    @import '../assets/scss/sprite/home@2x.scss';
    $list: $icon-1-2x, $icon-2-2x, $icon-3-2x;
    .loan .content {
      @for $i from 1 to length($list) + 1 {
        .item:nth-child(#{$i}) .icon {
          @include sprite(nth($list, $i));
        }
      }
    }
    $list-2: $step-1-2x, $step-2-2x, $step-3-2x, $step-4-2x;
    .step .content {
      @for $i from 1 to length($list-2) + 1 {
        .item:nth-child(#{$i}) .icon {
          @include sprite(nth($list-2, $i));
        }
      }
    }
  }
}

.page {
  @include pixel-ratio();
  text-align: center;
  /deep/ .el-carousel__container {
    height: 470px;
    .ratio {
      .el-carousel__item {
        &:nth-child(1) {
          background-image: url('~@/assets/img/banner_1@2x.png');
        }
        &:nth-child(2) {
          background-image: url('~@/assets/img/banner_2@2x.png');
        }
        &:nth-child(3) {
          background-image: url('~@/assets/img/banner_3@2x.png');
        }
      }
    }
    .el-carousel__item {
      background-size: cover;
      background-position: center;
      &:nth-child(1) {
        background-image: url('~@/assets/img/banner_1.png');
      }
      &:nth-child(2) {
        background-image: url('~@/assets/img/banner_2.png');
      }
      &:nth-child(3) {
        background-image: url('~@/assets/img/banner_3.png');
      }
      img {
        height: 100%;
      }
    }
  }
  /deep/ .el-carousel__indicators {
    .el-carousel__indicator {
      .el-carousel__button {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background-color: #F29E9C;
      }
    }
    .is-active .el-carousel__button {
      background-color: white;
    }
  }
  .block {
    max-width: 1200px;
    margin: 0 auto;
    .title {
      text-align: center;
      font-size: 24px;
      padding: 42px 0px;
      font-weight: 700;
    }
    &.loan {
      padding-bottom: 48px;
      .content {
        display: flex;
        .item {
          flex: 1;
          .icon {
            display: inline-block;
            margin-bottom: 20px;
          }
          .text {
            color: #252525;
            font-size: 20px;
            margin: 10px 0;
          }
          .info {
            color: #252525;
            font-size: 18px;
            /deep/ span {
              color: #CA6263;
            }
          }
        }
      }
    }
    &.step {
      padding-bottom: 48px;
      .title {
        padding: 42px 0px 20px 0;
      }
      .content {
        .item {
          display: flex;
          padding: 20px 0;
          height: 100px;
          .icon {
            display: inline-block;
            margin-right: 55px;
          }
          .text {
            flex: 1;
            text-align: left;
            color: #252525;
            font-size: 22px;
            /deep/ span {
              display: table-cell;
              vertical-align: middle;
              height: 100px;
            }
          }
        }
      }
    }
  }
}
</style>

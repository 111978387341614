export default {
  home: 'Home',
  about: 'About us',
  payment: 'Payment method',
  footer: {
    userAgreement: 'User agreement',
    securityPolicy: 'Security policy',
    listTips: [
      'Copyright © 2020 - 2021 ajeloan. All Rights Reserved.',
    ],
    contactUs: 'Contact us',
    phone: '+234 9039162704',
    email: 'CSKH@ajeloan.vn',
    facebookUrl: 'https://www.facebook.com/ajeloan.vn/?modal=admin_todo_tour',
    facebookName: 'Aje loan -Tài Chính',
  },
  homePage: {
    loan: {
      title: 'As long as you want to borrow, you will be able to borrow',
      list: [
        {
          text: 'Please fill in the application form',
          info: 'It only takes <span>5 minutes</span> to fill in information',
        },
        {
          text: 'Validation quickly',
          info: 'Quick verification in <span>2 hours</span>',
        },
        {
          text: 'Get the loan',
          info: 'Money to your account within <span>24h</span> registration.',
        },
      ],
    },
    step: {
      title: 'Additional Benefits of Aje loan',
      list: [
        'Choose "Limit" and "Term".',
        'You can apply for a loan whenever you need money.',
        'You can fill out the application form any day, night, weekend, holidays ... to receive a loan. We will ship them to you at any time.',
        'You just need to register and fill out the form. You only need an internet connection. The whole process is done directly, with no need to wait!',
      ],
    },
  },
  aboutPage: {
    title: 'About us',
  },
  paymentPage: {
    title: 'Company account',
    list: [
      {
        title: '1. Ngân hàng thương mại cổ phần Kỹ Thương Việt Nam (Techcombank).',
        list: [
          '1. Vietnam Technological and Commercial Joint Stock Bank (Techcombank).',
          'Branch: District 7, Phu My Hung Transaction Office',
          'Account number: 19134897785010',
          'Company name: WIN WIN FINANCE SERVICES CO.',
          'Payment content: Contract number + Customer ID number',
        ],
      },
      {
        title: '2. Saigon Thuong Tin Commercial Joint Stock Bank (Sacombank).',
        list: [
          'Bank name: Saigon Thuong Tin Joint Stock Commercial Bank',
          'Branch: District 7, Transaction Office Nguyen Luong Bang',
          'Account number: 060232692408',
          'Company name: WIN WIN FINANCE SERVICES CO.',
          'Payment content: Contract number + Customer ID number',
        ],
      },
      {
        title: '3. Joint Stock Commercial Bank for Foreign Trade of Vietnam (Vietcombank).',
        list: [
          'Bank name: Joint Stock Commercial Bank for Foreign Trade of Vietnam',
          'Branch: Saigon South Branch',
          'Account number: 0181003646354',
          'Company name: WIN WIN FINANCE SERVICES CO.',
          'Payment content: Contract number + Customer ID number',
        ],
      },
    ],
    info1: 'Working hours of banks are as follows:',
    info2: 'From Monday to Friday, 8:00 am to 12:00; 13: 30-17: 00.',
  },
};
